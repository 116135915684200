import React from "react";
import { MDBInput } from 'mdbreact';


const TextInput = props => {
    return (
        <MDBInput {...props} {...props.field} />
    );
};


export default TextInput;

