import React, { useState, useEffect } from "react";
import Table from "../../components/table/Table";
import Loader from "../../components/Loader";
import SearchPanel from "../../components/table/SearchPanel";
import Pagination from "../../components/pagination/Pagination";
import { getArticles, getArticleDetails } from "../../helpers/articles";
import Modal from "../../components/modal/Modal";
import { itemsPerPage } from "../../constants";


const Articles = () => {
    const [articles, setArticles] = useState(null);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(1);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [sortBy, setSortBy] = useState("id");
    const [sortDir, setSortDir] = useState("asc");
    const [searchValue, setSearchValue] = useState("");
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'manuscript ID',
                accessor: 'manuscript_id',
            },
            {
                Header: 'DOI',
                accessor: 'doi',
            },
            {
                Header: 'journal title',
                accessor: 'journal',
            },
            {
                Header: 'manuscript title',
                accessor: 'manuscript_title'
            },
            {
                Header: 'last event',
                accessor: 'lastevent',
            }
        ], []
    );

    useEffect(() => {
        if (selectedMessage) {
            setLoading(true)
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setLoading(false))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    useEffect(() => {
        setLoading(true);
        getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "published")
            .then(res => {
                setArticles(res.data.articles);
                setPageCount(Math.ceil(res.data.total / itemsPerPage));
            })
            .then(setLoading(false))
            .catch(error => error)
    }, [offset, sortBy, sortDir]);

    const handlePageClick = data => {
        const selectedPage = data.selected;
        const offset = selectedPage * itemsPerPage;
        setCurrentPage(selectedPage);
        setOffset(offset + 1);
    };

    const onSearch = () => {
        setLoading(true);
        getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "")
            .then(res => {
                setOffset(1);
                setCurrentPage(0);
                setArticles(res.data.articles);
                setPageCount(Math.ceil(res.data.total / itemsPerPage));
            })
            .then(setLoading(false))
            .catch(error => error)
    };


    return (
        <div className="container">
            {
                loading || !articles ?
                    <Loader />
                    :
                    <>
                        <div className="d-flex align-items-baseline justify-content-between mt-5">
                            <Pagination
                                handlePageClick={handlePageClick}
                                pageCount={pageCount}
                                articles={articles}
                                currentPage={currentPage}
                            />

                            <SearchPanel
                                onSearch={onSearch}
                                setSearchValue={setSearchValue}
                                searchValue={searchValue}
                            />
                        </div>

                        <Modal
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            articleDetails={articleDetails}
                        />

                        <Table
                            data={articles || []}
                            columns={columns}
                            sortBy={sortBy}
                            sortDir={sortDir}
                            setSelectedMessage={setSelectedMessage}
                            setSortBy={setSortBy}
                            setSortDir={setSortDir}
                        />
                    </>
            }
        </div>
    );
};


export default Articles;