import React from "react";
import { MDBFooter } from "mdbreact";
import logo from "../images/appetence.png";


const Footer = () => {
    return (
        <MDBFooter color="special_color_dark" className="font-small mt-4 footer">
            <img src={logo} alt="logotype" className="logotype-appetence ml-5"></img>
            <span><strong>Publication Tracker</strong> - from submitted manuscript to published article</span>
        </MDBFooter>
    );
};


export default Footer;

