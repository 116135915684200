import React from "react";
import { MDBTabPane, MDBModalBody } from "mdbreact";
import ModalContent from "./ModalContent";


const ModalTabItem = ({ tabId, data, headerItems, showInline }) => {

    return (
        <MDBTabPane tabId={tabId} role="tabpanel">
            <MDBModalBody>
                <ModalContent
                    data={data}
                    headerItems={headerItems}
                    showInline={showInline}
                    tabId={tabId}
                />
            </MDBModalBody>
        </MDBTabPane>
    );
};


export default ModalTabItem;