import React from "react";
import { parseData } from "../helpers/helpers";
import SimpleRenderList from "./SimpleRenderList";


const ComplexRenderList = ({ items }) => {
    const header = items.key;
    const content = parseData(items.value);

    return (
        <>
            <h5 className="mt-2 font-weight-bolder details_header">{header}:</h5>
            <SimpleRenderList items={content} />
        </>
    );
};


export default ComplexRenderList;

