import React, { useState } from "react";
import { MDBBtn, MDBModal, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { MDBTabContent, MDBNav } from "mdbreact";
import ModalHeaderItem from "./ModalHeaderItem";
import ModalTabItem from "./ModalTabItem";
import { BrowserRouter as Router } from 'react-router-dom';


const Modal = ({ isOpen, setIsOpen, articleDetails }) => {
    const [activeItem, setActiveItem] = useState("Metadata");

    const toggle = tab => {
        if (activeItem !== tab) {
            setActiveItem(tab)
        };
    };

    const eventsItems = ["type", "state"];
    const messagesItems = ["id", "type", "created"];


    return (
        <MDBModal isOpen={isOpen} size="lg" toggle={() => setIsOpen(!isOpen)}>
            <MDBModalHeader className="special_color mb-4" toggle={() => setIsOpen(false)}>
                <Router>
                    <MDBNav tabs>
                        <ModalHeaderItem activeItem={activeItem} itemTitle={"Metadata"} toggle={toggle} />
                        <ModalHeaderItem activeItem={activeItem} itemTitle={"Events"} toggle={toggle} />
                        {
                            articleDetails?.messages && articleDetails.messages.length > 0
                                ?
                                <ModalHeaderItem activeItem={activeItem} itemTitle={"Messages"} toggle={toggle} />
                                :
                                null
                        }
                        <ModalHeaderItem activeItem={activeItem} itemTitle={"Affiliations"} toggle={toggle} />
                    </MDBNav>
                </Router>
            </MDBModalHeader>

            <MDBTabContent activeItem={activeItem}>
                <ModalTabItem
                    tabId={"Metadata"}
                    data={articleDetails?.article}
                />
                <ModalTabItem
                    tabId={"Events"}
                    data={articleDetails?.events}
                    headerItems={eventsItems}
                    showInline
                />
                <ModalTabItem
                    tabId={"Messages"}
                    data={articleDetails?.messages}
                    headerItems={messagesItems}
                />
                <ModalTabItem
                    tabId={"Affiliations"}
                    data={articleDetails?.article}
                    headerItems={eventsItems}
                />
            </MDBTabContent>

            <MDBModalFooter>
                <MDBBtn color="special_color" className="special_color_dark text-white"
                    onClick={() => setIsOpen(false)}>
                    Close
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
};


export default Modal;

