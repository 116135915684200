import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from './pages/login/Login';
import Pipeline from './pages/pipeline/Pipeline';
import { token } from "./constants";
import Navbar from './components/Navbar';
import Payoff from './components/Payoff';
import Published from './pages/published/Published';
import Reports from './pages/reports/Reports';
import Identifiers from './pages/identifiers/Identifiers';


const PrivateRoute = ({ component: Component, ...rest }) => {
    const authorized = localStorage.getItem(token);

    return (
        <Route {...rest}
            render={props => authorized
                ?
                <>
                    <Payoff />
                    <Navbar />
                    <Component {...props} />
                </>
                :
                <Redirect to="/login" />
            } />
    );
};


const Routes = () => {
    return (
        <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute exact path="/" component={Pipeline} />
            <PrivateRoute path="/pipeline" component={Pipeline} />
            <PrivateRoute path="/published" component={Published} />
            <PrivateRoute path="/reports" component={Reports} />
            <PrivateRoute path="/identifiers" component={Identifiers} />
        </Switch>
    );
};


export default Routes;