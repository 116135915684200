import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBBtn } from "mdbreact";
import { logOut } from "../helpers/auth";


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => {
    setIsOpen(open => !open);
  };


  return (
    <MDBNavbar color="special_color px-5" dark expand="md">
      <MDBNavbarToggler onClick={toggleCollapse} />
      <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
        <MDBNavbarNav left>
          <MDBNavItem>
            <Link className="nav-link mr-3 font-weight-bolder" to="/pipeline">Pipeline</Link >
          </MDBNavItem>
          <MDBNavItem>
            <Link className="nav-link mr-3 font-weight-bolder" to="/published">Published</Link >
          </MDBNavItem>
          <MDBNavItem>
            <Link className="nav-link mr-3 font-weight-bolder" to="/reports">Reports</Link >
          </MDBNavItem>
          <MDBNavItem>
            <Link className="nav-link mr-3 font-weight-bolder" to="/identifiers">Update identifiers</Link >
          </MDBNavItem>
        </MDBNavbarNav>
        <MDBNavbarNav right>
          <MDBNavItem>
            <MDBBtn onClick={logOut} color="light" size="sm">Log Out</MDBBtn>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
};


export default Navbar;