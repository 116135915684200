import React from "react";
import Routes from "./routes";
import Footer from "./components/Footer";
import "./App.css"


const App = () => {
  return (
    <>
      <Routes />
      <Footer />
    </>
  );
};


export default App;