import React from "react";
import { filterInstitutions } from "../helpers/helpers";
import AffiliationItem from "./AffiliationItem";


const Affiliations = ({ article }) => {
  const authors = article?.authors || [];
  const uniqueInstitutions = filterInstitutions(authors);


  return (
    <>
      <span className="font-weight-bolder details_header">Institutions: </span>
      <ul>
        {authors ?
          uniqueInstitutions.map(item => {
            return (
              <AffiliationItem key={item.name} item={item} />
            )
          })
          :
          "-"
        }
      </ul>
    </>
  );
};


export default Affiliations;

