import React from "react";
import { MDBNavbar, MDBNavbarBrand  } from "mdbreact";
import logo from "../images/logo.png";


const Navbar = () => {

  return (
    <MDBNavbar color="special_color px-5" dark expand="md">
      <MDBNavbarBrand>
          <div className="logo_container">
            <div className="pubtrack-name">Publication Tracker</div>
            <div className="pubtrack-divider"> | </div>
            <img src={logo} alt="logotype" className="logotype"></img>
          </div>
      </MDBNavbarBrand>
    </MDBNavbar>
  );
};


export default Navbar;