import React from "react";
import { MDBCard } from 'mdbreact';


const Card = ({ title, content }) => {

    return (
        <MDBCard>
            <h4 className="mt-4 mb-2 text-center">{title}</h4>
            {content}
        </MDBCard>
    );
};


export default Card;