import React from 'react';
import ReactPaginate from 'react-paginate';
import "./Pagination.css";


const Pagination = ({ handlePageClick, pageCount, currentPage, articles }) => {
  const paginationClassname = articles.length > 0 ? "pagination" : "pagination hide";

  return (
    <ReactPaginate
      previousLabel={'previous'}
      previousClassName={currentPage + 1 > 1 ? 'previous' : 'hide'}
      nextClassName={currentPage + 1 < pageCount ? 'next' : 'hide'}
      nextLabel={'next'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      forcePage={currentPage}
      pageClassName={'li_styles'}
      pageCount={pageCount}
      marginPagesDisplayed={3}
      pageRangeDisplayed={2}
      onPageChange={handlePageClick}
      containerClassName={paginationClassname}
      activeClassName={'active'}
    />
  );
};

export default Pagination;
