import React from "react";
import { Pie } from 'react-chartjs-2';
import { colorsPalette } from "../../constants";


const PieChart = ({ data }) => {
    const pieChartData = {
        labels: data.map(item => item.institution),
        datasets: [
            {
                data: data.map(item => item.count),
                backgroundColor: colorsPalette,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                position: "bottom",
            },
        },
    };


    return (
        <div className="m-3">
            <Pie data={pieChartData} options={options} />
        </div>
    );
};


export default PieChart;