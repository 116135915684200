import React from "react";
import ComplexRenderList from "./ComplexRenderList";


const SimpleRenderList = props => {
    const items = props.items || [];

    return (
        <ul>
            {items.map((item, i) => {
                return typeof item.value !== 'object'
                    ?
                    <li key={i}>
                        <span className="font-weight-bolder">{item.key}:</span>
                        {` ${item.value}`}
                    </li>
                    :
                    <ComplexRenderList items={item} key={i} />
            })}
        </ul>
    );
};


export default SimpleRenderList;

