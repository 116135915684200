import React from "react";


const Loader = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border text-dark mt-5" role="status"></div>
        </div>
    );
};


export default Loader;

