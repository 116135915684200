import AccordionHeaderItem from "../components/accordion/AccordionHeaderItem";


export const parseData = data => {
    if (data && typeof data === 'object') {
        const keys = Object.keys(data);
        const complexItems = [];
        const simpleItems = keys
            .map(objKey => {
                if (typeof data[objKey] !== 'object') {
                    return { key: objKey, value: data[objKey] };
                }
                const key = Number.isInteger(Number(objKey)) ? Number(objKey) + 1 : objKey;
                complexItems.push({ key, value: data[objKey] || `${data[objKey]}` });
                return null;
            })
            .filter(item => item);
        return simpleItems.concat(complexItems);
    };
};

export const filterInstitutions = items => {
    const set = [];

    items.forEach(author => {
        author.institutions.forEach(institution => {
            const value = set.find(item => item.name === institution.name);

            if (!value) {
                set.push(institution)
            }
        });
    });
    return set;
};

export const renderAccordionHeaderItems = (arr, item, showInline) => {
    return arr.map(key => {
        return (
            <AccordionHeaderItem
                key={key}
                title={`${key}: `}
                value={item[`${key}`]}
                showInline={showInline}
            />
        )
    });
};