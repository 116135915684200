import React from 'react';


const AccordionHeaderItem = ({ title, value, showInline }) => {
    const HeaderItemClassName = showInline ? "mr-4" : "";

    return (
        <div className={HeaderItemClassName}>
            <span className="font-weight-bolder">{title}</span>
            <span>{value}</span>
        </div>
    );
};


export default AccordionHeaderItem;

