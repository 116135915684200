import React, { useEffect } from "react";
import { MDBBtn } from "mdbreact";
import { Field, withFormik } from "formik";
import TextInput from "./TextInput";


const AffiliationItem = ({ item, setValues, touched, setFieldTouched, handleSubmit }) => {
  useEffect(() => {
    setValues({
      ror: item.address || ""
    })
  }, [item, setValues]);


  return (
    <li>
      <span>{item.name}</span>
      <form className="affiliations_form" onSubmit={handleSubmit}>
        <Field onFocus={() => setFieldTouched("ror", true)}
          component={TextInput} name="ror" label="Ror" type="text" />

        {touched.ror &&
          <MDBBtn color="special_color_dark" className="special_color_dark text-white" type="submit">save</MDBBtn>
        }
      </form>
    </li>
  );
};


const mapPropsToValues = props => ({
  ror: props.ror
});

const handleSubmit = (value, { setTouched }) => {
  console.log(value);
  setTouched({
    ror: false
  })
};


export default withFormik({ mapPropsToValues, handleSubmit })(AffiliationItem);
