import React, { useState, useEffect } from 'react';
import { MDBCollapse, MDBCard, MDBCardBody, MDBIcon } from "mdbreact";
import { parseData, renderAccordionHeaderItems } from "../../helpers/helpers";
import SimpleRenderList from "../SimpleRenderList";


const Accordion = ({ items, headerItems, showInline }) => {
    const [collapseID, setCollapseID] = useState(null);

    useEffect(() => {
        if (items.length > 0) {
            setCollapseID(items[0].id + "");
        }
    }, [items]);

    const setId = item => {
        const itemId = item.id + "";
        setCollapseID(id => id !== itemId ? itemId : null);
    };

    const accordionHeaderClassName = showInline ? "d-flex" : "";


    return (
        items.map(item => {
            return (
                <MDBCard className='mt-3' key={item.id}>
                    <div className='py-3 pl-3 d-flex align-items-center justify-content-between special_color text-white accordion_header' onClick={() => setId(item)}>
                        <div className={accordionHeaderClassName}>
                            {
                                renderAccordionHeaderItems(headerItems, item, showInline)
                            }
                        </div>
                        <MDBIcon className="mr-3" icon={collapseID === item.id + "" ? 'angle-up' : 'angle-down'} />
                    </div>

                    <MDBCollapse id={item.id + ""} isOpen={collapseID}>
                        <MDBCardBody>
                            <SimpleRenderList items={parseData(item)} />
                        </MDBCardBody>
                    </MDBCollapse>
                </MDBCard>
            )
        })
    );
};


export default Accordion;

